import { css } from '@emotion/core';
import styled from '@emotion/styled';

const wrapperStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  margin: 0 -15px;
  padding: 70px 15px 0;
  background: ${work.rivieraTour};
  ${breakpoint.min730} {
    height: 100vh;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1300px;
    margin: 0;
    padding: 0;
  }
  ${'' /* > 1900px */}
  @media screen and (min-width: 118.75em) {
    width: 100vw;
  }
`;

const imageWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  width: 100%;
  max-width: 670px;
  align-self: flex-end;
  margin: 0 auto;
  ${breakpoint.min730} {
    margin: 0 150px 0 0;
    justify-self: flex-end;
  }
  ${'' /* <990px, 610px max-height - small tablets, landscape mode*/}
  @media screen and (max-width: 61.875em) and (max-height: 38.125em) and (orientation: landscape) {
    max-width: 550px;
  }
  ${'' /* > 2000px */}
  @media screen and (min-width: 125em) {
    max-width: 900px;
  }
`;

const sectionStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  align-self: flex-start;
  margin: 0 0 60px;
  ${breakpoint.min730} {
    width: 500px;
    margin: 150px 0 0 150px;
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const Section = styled('div')(sectionStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
