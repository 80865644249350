import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Heading, TextAccent, Link, ArrowExternal } from '../../../components';
import { Wrapper, Section, ImageWrapper, Block, SearchTerm, SearchFeature } from './KironFeatures.style';
import searchIcon from '../../../images/search.svg';

/**
 * Work/Kiron: Backend composition
 */
const KironFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      kiron: file(relativePath: { eq: "kiron-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Section>
        <Block gridArea="rapid-development">
          <Heading element="h3" size="h4" weight={700}>
            Rapid Development
          </Heading>
          <TextAccent>
            Although a reasonably small team, we manage to perform high without affecting a healthy work-life balance.
            Well-defined processes across the organization, with optimized tooling, have certainly contributed to that
            challenge!
          </TextAccent>
        </Block>
        <Block gridArea="ui-architecture">
          <Heading element="h3" size="h4" weight={700}>
            UI Architecture
          </Heading>
          <TextAccent withSpacing>
            When I joined Kiron, one of my main challenges was to make our codebase up-to-date with latest trends. This
            effort involved improving tooling, migrating the existing codebase to Emotion with more scalable UI
            architecture, and collaborating with the design team to develop our internal design system.
          </TextAccent>
          <TextAccent>
            I was also responsible for improving <strong>testing strategies</strong>. For this matter, we replaced our
            Enzyme configuration with React Testing Library and configured Cypress to work with our local database setup
            by replicating the base environment on every run.
          </TextAccent>
        </Block>
        <Block gridArea="image">
          <ImageWrapper>
            <Img
              fluid={data.kiron.childImageSharp.fluid}
              loading="eager"
              imgStyle={{ objectFit: 'contain' }}
              alt="Image of Kiron website"
            />
          </ImageWrapper>
        </Block>
        <Block gridArea="white-label">
          <Heading element="h3" size="h4" weight={700}>
            Scaling Our Reach
          </Heading>
          <TextAccent withSpacing>
            Our major milestone for 2020 was to navigate our organization towards for-profit ventures. We noticed the
            world needs a social learning platform that would support online learners during the challenges imposed by
            the pandemic that is safe, secure, and well established.
          </TextAccent>
          <TextAccent>
            During 2020 and 2021, we acquired several partners across the EU and enabled access to necessary learning
            materials to thousands of students.
          </TextAccent>
        </Block>
        <Block gridArea="i18n">
          <Heading element="h3" size="h4" weight={700}>
            Internationalization
          </Heading>
          <TextAccent>
            We spent a fair amount of time providing multi-language support to our students. We made a particular effort
            to make our content properly presented for the RTL usage since a certain number of our users come from Arab
            countries.
          </TextAccent>
        </Block>
        <Block gridArea="authentication">
          <Heading element="h3" size="h4" weight={700}>
            Authentication
          </Heading>
          <TextAccent>
            In early 2020, we decided to improve our authentication flows to enable our users easier access to the
            application with proper support when they lose their credentials.
          </TextAccent>
        </Block>
        <Block gridArea="search">
          <SearchFeature>
            <img src={searchIcon} alt="Search icon" />
            <SearchTerm>Artificial Intelligence Courses</SearchTerm>
          </SearchFeature>
          <Heading element="h3" size="h4" weight={700}>
            Search
          </Heading>
          <TextAccent>
            We use{' '}
            <Link to="https://www.algolia.com/">
              Algolia
              <ArrowExternal />
            </Link>{' '}
            to provide our users with fast and reliable results while exploring our platform. We decided to only expose
            a small part of our DB for security reasons, which proved to be a peculiar challenge for data syncing since
            we publish numerous courses daily.
          </TextAccent>
        </Block>
        <Block gridArea="third-party">
          <Heading element="h3" size="h4" weight={700}>
            3rd Party Integrations
          </Heading>
          <TextAccent>
            We introduced various integrations to our platform: from enabling SSO connection for our partner
            universities, providing the creation of interactive learning materials via{' '}
            <Link to="https://h5p.org/">
              h5p
              <ArrowExternal />
            </Link>
            , to improving student communication via{' '}
            <Link to="https://mattermost.com/">
              Mattermost
              <ArrowExternal />
            </Link>{' '}
            to name a few.
          </TextAccent>
        </Block>
        <Block gridArea="graphql">
          <Heading element="h3" size="h4" weight={700}>
            GraphQL and Business Layer
          </Heading>
          <TextAccent>
            As one of the <strong>most exciting</strong> parts of my day-to-day responsibilities, I have the opportunity
            to work and improve our internal data modeling tool from which we can generate a back-office interface for
            our admin users. Also, this tool produces TypeScript types and optimized data loaders for more performant
            access to our database via GraphQL. To keep the business layer aligned to our future challenges, we heavily
            invested our efforts in finding ways to migrate our infrastructure from Laravel to Node.
          </TextAccent>
        </Block>
      </Section>
    </Wrapper>
  );
};

export default KironFeatures;
