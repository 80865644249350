import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const wrapperStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  background: ${work.kiron};
  padding: 70px 15px;
  margin: 0 -15px;
  ${breakpoint.min730} {
    padding: 0;
    margin: auto;
    display: grid;
    min-height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 0;
  }
`;

const imageWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => `
  width: 100%;
  margin: 0 auto;
  ${breakpoint.min730} {
    max-width: 500px;
  }
`;

const sectionStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: grid;
  row-gap: 0;
  grid-template-areas:
    'image'
    'rapid-development'
    'ui-architecture'
    'i18n'
    'white-label'
    'search'
    'authentication'
    'third-party'
    'graphql';
  ${breakpoint.fromPhoneMedium} {
    column-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'image             image'
      'rapid-development i18n'
      'white-label       white-label'
      'search            search'
      'ui-architecture   ui-architecture'
      'authentication    third-party'
      'graphql           graphql';
  }
  ${breakpoint.min730} {
    align-self: flex-end;
    margin: 0 150px;
    grid-template-columns: repeat(8, 300px);
    grid-template-areas:
      '.                 ui-architecture image image          white-label white-label third-party .'
      'rapid-development ui-architecture i18n  authentication graphql     graphql     search      search';
    row-gap: 0;
    column-gap: 30px;
    align-self: flex-end;
  }
`;

const blockStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
  gridArea,
}) => css`
  grid-area: ${gridArea};
  border-top: 1px solid ${color.grey900Transparent15};
  padding: 40px 0;
  &:last-of-type {
    padding-bottom: 0;
  }
  ${breakpoint.min730} {
    &:last-of-type {
      padding-bottom: 40px;
    }
  }
`;

const blockWithImageStyles = ({
  theme: {
    grid: { breakpoint },
  },
  gridArea,
}) =>
  gridArea === 'image' &&
  css`
    border-top: none;
    padding-top: 0;
    ${breakpoint.min730} {
      padding-top: 40px;
      transform: scale(1.25) translateY(-30px);
    }
  `;

const blockHiddenMobileStyles = ({
  theme: {
    grid: { breakpoint },
  },
  gridArea,
}) =>
  ['rapid-development', 'i18n', 'search', 'authentication', ''].includes(gridArea) &&
  css`
    ${breakpoint.toPhoneMedium} {
      display: none;
    }
  `;

const searchFeatureStyles = ({ theme: { color } }) => css`
  border: 1px solid ${color.blueGrey100};
  box-shadow: 0 2px 5px ${color.grey900Transparent15};
  border-radius: 5px;
  padding: 15px 10px;
  color: ${color.black};
  background: ${color.white};
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  img {
    margin-right: 8px;
  }
`;

const blinkCursor = keyframes`
  0%{
    border-right-color: #00acee;
  }

  50% {
    border-right-color: transparent;
  }

  100%{
    border-right-color: #00acee;
  }
`;

const searchTermStyles = css`
  border-right: 3px solid transparent;
  line-height: 1.4;
  animation: ${blinkCursor} 1200ms infinite normal;
  padding-right: 4px;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const Section = styled('div')(sectionStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
export const Block = styled('div')(blockStyles, blockWithImageStyles, blockHiddenMobileStyles);
export const SearchTerm = styled('span')(searchTermStyles);
export const SearchFeature = styled('div')(searchFeatureStyles);
