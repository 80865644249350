import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TextAccent } from '../../../components';
import {
  Wrapper,
  ColumnOne,
  ColumnTwo,
  ItenPictogramWhite,
  ItenPictogramDark,
  ItenSineWave,
  RDesktopLogo,
  VirtualCooperate,
  RDesktop,
  ItenLogo,
  ItenServices,
  Social,
} from './ItenEngineering.style';
import itenPictogramWhite from '../../../images/iten-pictogram-white.svg';
import itenPictogram from '../../../images/iten-pictogram.svg';
import sineWave from '../../../images/tile-sine-wave.mp4';
import sineWaveSVG from '../../../images/tile-sine-wave.svg';
import rDesktopLogo from '../../../images/r-desktop.svg';
import itenLogo from '../../../images/iten-logo.svg';

/**
 * Work/Personal: ItenEngineering composition
 */
const ItenEngineering = () => {
  const yearInMs = 365 * 24 * 60 * 60 * 1000;
  const year = Math.ceil((Date.now() - new Date(2014, 8, 1)) / yearInMs);

  const data = useStaticQuery(graphql`
    query {
      virtualcooperate: file(relativePath: { eq: "virtual-cooperative.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rDesktop: file(relativePath: { eq: "rdesktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      itenServices: file(relativePath: { eq: "iten-engineering-services.png" }) {
        childImageSharp {
          fluid(maxWidth: 1050) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      social: file(relativePath: { eq: "social.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1050) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <ColumnOne>
        <TextAccent>
          <strong>Iten Engineering</strong> — I’ve been working with Iten Engineering for more than {year} years now.
          Our collaboration has spanned across many areas of their products and marketing efforts. From their corporate
          website and blog, through branding for digital and print media, to their ventures, including remote desktop
          access solution and social platform for helping farmers enter the international market.
        </TextAccent>
      </ColumnOne>
      <ColumnTwo>
        <ItenPictogramWhite>
          <img src={itenPictogram} alt="Pictogram of Iten Engineering on white background" />
        </ItenPictogramWhite>
        <ItenPictogramDark>
          <img src={itenPictogramWhite} alt="Pictogram of Iten Engineering on dark background" />
        </ItenPictogramDark>
        <ItenSineWave>
          <video poster={sineWaveSVG} width="450" height="93" preload={true} autoPlay={true} loop={true} muted={true}>
            <source src={sineWave} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </ItenSineWave>
        <RDesktopLogo>
          <img src={rDesktopLogo} alt="Logo presentation of R Desktop" />
        </RDesktopLogo>
        <VirtualCooperate>
          <div className="imageWrapper">
            <Img
              fluid={data.virtualcooperate.childImageSharp.fluid}
              loading="eager"
              alt="Image of Virtual Cooperate project"
            />
          </div>
        </VirtualCooperate>
        <RDesktop>
          <Img fluid={data.rDesktop.childImageSharp.fluid} loading="eager" alt="Image of R Desktop project" />
        </RDesktop>
        <ItenLogo>
          <img src={itenLogo} alt="Logo of Iten Engineering" />
        </ItenLogo>
        <Social>
          <div className="imageWrapper">
            <Img fluid={data.social.childImageSharp.fluid} loading="eager" alt="Image of Social project" />
          </div>
        </Social>
        <ItenServices>
          <div className="imageWrapper">
            <Img
              fluid={data.itenServices.childImageSharp.fluid}
              loading="eager"
              alt="Image of Iten Engineering project"
            />
          </div>
        </ItenServices>
      </ColumnTwo>
    </Wrapper>
  );
};

export default ItenEngineering;
