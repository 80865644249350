import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TextAccent } from '../../../components';
import { Wrapper, SectionOne, SectionTwo, ImageWrapper } from './ScrumSpace.style';

/**
 * Work/Blueberry: ScrumSpace composition
 */
const ScrumSpace = () => {
  const data = useStaticQuery(graphql`
    query {
      scrumspace: file(relativePath: { eq: "scrum-space-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <SectionOne>
        <ImageWrapper>
          <Img
            fluid={data.scrumspace.childImageSharp.fluid}
            loading="eager"
            imgStyle={{ objectFit: 'contain' }}
            alt="Image of Scrum Space project presentation"
          />
        </ImageWrapper>
      </SectionOne>
      <SectionTwo>
        <main>
          <TextAccent withSpacing>
            <strong>Scrum Space</strong> — We built Scrum space intending to make the scrum ceremonies easier and fun to
            use.
          </TextAccent>
          <TextAccent>
            As one of the internal projects we worked on, we decided to go with ReasonML, streams and we also
            experimented with the registration-free approach.
          </TextAccent>
        </main>
      </SectionTwo>
    </Wrapper>
  );
};

export default ScrumSpace;
