import React from 'react';
import { Arrow } from '../../components';
import { NavWrapper, NavText, NavList, NavItem, NavLink } from './Work.style';

/**
 * Work: StickyNavigation composition
 */
const StickyNavigation = () => (
  <NavWrapper>
    <NavText>
      Jump to <Arrow fill="white" size={10} />
    </NavText>{' '}
    <NavList>
      <NavItem>
        <NavLink href="#section:sesame">Sesame</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:kiron">Kiron</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:blueberry">Blueberry</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:superawesome">Superawesome</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:personal">Freelance / personal work</NavLink>
      </NavItem>
    </NavList>
  </NavWrapper>
);

export default StickyNavigation;
