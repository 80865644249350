import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { linkStyles } from '../../components/Link/Link.style';
import { rem } from '../../utils/utils';

const introductionStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  display: flex;
  align-items: center;
  padding: 70px 0;
  background: ${color.white};
  ${breakpoint.min730} {
    width: 100vw;
    min-width: 100vw;
    min-height: 100vh;
    padding: 0;
  }
  ${breakpoint.fromDesktop} {
    ${'' /* Taken from storytelling section */}
    width: calc(100vw - (100vw - 960px) / 2);
  }
`;

const contentStyles = ({
  theme: {
    grid: { container, breakpoint },
  },
}) => css`
  ${'' /* > 730px */}
  @media screen and (min-width: 45.625em) {
    padding-right: 30px;
  }
  ${breakpoint.fromTablet} {
    max-width: calc(100% - 30px);
    ${'' /* max-width: calc(80% - 30px); */}
    padding: 0;
  }
  ${breakpoint.fromDesktop} {
    max-width: ${container.default};
  }
`;

const logoStyles = css`
  margin-right: 8px;
  position: relative;
  top: 8px;
`;

/** Sticky navigation */
export const navWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  position: sticky;
  top: 15px;
  z-index: 5;
  mix-blend-mode: difference;
  color: white;
  display: none;
  padding: 15px 0;
  ${breakpoint.min730} {
    display: flex;
  }

  &:hover {
    ul {
      opacity: 1;
    }
  }
`;

const navTextStyles = css`
  font-size: ${rem(12)};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  align-self: center;
  line-height: 18px;
`;

const navListStyles = css`
  opacity: 0;
  transition: 0.1s all ease-in-out;
  padding: 0;
  margin: 0;
  line-height: 1;
`;

const navItemStyles = css`
  display: inline-block;
  margin-left: 25px;
`;

const navLinkStyles = css`
  color: white;
  text-decoration: none;
  font-size: ${rem(16)};
  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: white;
    opacity: 0.6;
  }
`;

/**
 * Component style declarations
 */
export const Introduction = styled('div')(introductionStyles);
export const Content = styled('div')(contentStyles);
export const Logo = styled('img')(logoStyles);
export const NavWrapper = styled('nav')(navWrapperStyles);
export const NavText = styled('span')(navTextStyles);
export const NavList = styled('ul')(navListStyles);
export const NavItem = styled('li')(navItemStyles);
export const NavLink = styled('a')(linkStyles, navLinkStyles);
