import React from 'react';
import { TextAccent, Link, ArrowExternal } from '../../../components';
import { Wrapper, Column, HeyPolls, Label } from './PersonalWork.style';

/**
 * Work/Personal: PersonalWork composition
 */
const PersonalWork = () => (
  <Wrapper>
    <Column>
      <HeyPolls />
      <Label>Personal project</Label>
      <TextAccent withSpacing>
        <strong>Hey, Polls!</strong> — Create and share polls effortlessly. Making a choice has never been so easy
        before. Development stack involves: React, Redux, Firebase, Cypress, Jest, Emotion and{' '}
        <Link to="https://heypolls.netlify.app">
          Docz styleguide
          <ArrowExternal />
        </Link>
        .
      </TextAccent>
      <TextAccent>
        Check out the{' '}
        <Link to="https://heypolls.com/">
          web
          <ArrowExternal />
        </Link>
        ,{' '}
        <Link to="https://play.google.com/store/apps/details?id=app.heypolls">
          Android application
          <ArrowExternal />
        </Link>
        , or interesting details and thoughts <Link to="/blog/heypolls">on my blog</Link>.
      </TextAccent>
    </Column>
    <Column>
      <Label>Open Source projects:</Label>
      <TextAccent withSpacing>
        <strong>Transliterate</strong> — VS Code extension which converts latin text to cyrillic and vice versa.{' '}
        <Link to="https://marketplace.visualstudio.com/items?itemName=danijel-grabez.transliterate">
          Убрзај мапирање
          <ArrowExternal />
        </Link>
      </TextAccent>
      <TextAccent withSpacing>
        <strong>Responsively</strong> — Easily adjust JSX components with a responsive, mobile-first approach in mind.{' '}
        <Link to="https://github.com/danijelgrabez/responsively">
          Style the way
          <ArrowExternal />
        </Link>
      </TextAccent>
      <TextAccent withSpacing>
        <strong>Lifeboat and Lifeboat Jade</strong> — Used on numerous front-end projects with built-in code guide and
        site pages provided.{' '}
        <Link to="https://bitbucket.org/danijel_grabez/lifeboat-jade/src">
          Keep afloat
          <ArrowExternal />
        </Link>
      </TextAccent>
      <TextAccent>
        <strong>Other projects</strong> — I created several npm packages and demos using React Native with the primary
        focus on animation. Most of the work can be found on my{' '}
        <Link to="https://github.com/danijelgrabez?tab=repositories&q=&type=source">
          GitHub profile
          <ArrowExternal />
        </Link>
        .
      </TextAccent>
    </Column>
  </Wrapper>
);

export default PersonalWork;
