import { css } from '@emotion/core';
import styled from '@emotion/styled';
import panoram from '../../../images/panoram-bg.jpg';

const wrapperStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  margin: 0 -15px 60px -15px;
  padding: 0 15px;
  background-color: ${work.panoram};
  ${breakpoint.fromPhoneMedium} {
    background-image: url(${panoram});
    background-size: cover;
    background-repeat: no-repeat;
  }
  ${breakpoint.min730} {
    height: 100vh;
    display: grid;
    align-items: flex-end;
    grid-template-columns: 1fr 1300px;
    margin: 0;
    padding: 0;
  }
  ${'' /* > 1900px */}
  @media screen and (min-width: 118.75em) {
    width: 100vw;
  }
`;

const imageWrapperStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  ${breakpoint.min730} {
    max-width: 480px;
    margin: 0 150px 0 0;
    justify-self: flex-end;
  }
  ${'' /* <990px, 610px max-height - small tablets, landscape mode*/}
  @media screen and (max-width: 61.875em) and (max-height: 38.125em) and (orientation: landscape) {
    max-width: 400px;
  }
  ${'' /* > 2000px */}
  @media screen and (min-width: 125em) {
    max-width: 650px;
  }
`;

const sectionStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  align-self: flex-start;
  padding-top: 70px;
  margin: 0 0 60px;
  ${breakpoint.min730} {
    width: 500px;
    margin: 150px 0 0 150px;
    padding-top: 0;
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const Section = styled('div')(sectionStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
