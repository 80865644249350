import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TextAccent } from '../../../components';
import { Wrapper, Section, ImageWrapper } from './Echo.style';

/**
 * Work/Blueberry: Echo composition
 */
const Echo = () => {
  const data = useStaticQuery(graphql`
    query {
      echo: file(relativePath: { eq: "echo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Section>
        <TextAccent colorKind="light" withSpacing>
          <strong>Echo</strong> — In cooperation with the Czech Ministry of Internal Affairs, we developed a mobile
          application to involve the general public in the search for missing children.
        </TextAccent>
        <TextAccent colorKind="light">
          Used technologies: React Native, GraphQL, AWS Serverless and Styled Components.
        </TextAccent>
      </Section>
      <ImageWrapper>
        <Img
          fluid={data.echo.childImageSharp.fluid}
          loading="eager"
          imgStyle={{ objectFit: 'contain' }}
          alt="Image of Echo project presentation"
        />
      </ImageWrapper>
    </Wrapper>
  );
};

export default Echo;
