import React from 'react';
import { StorytellingSection, Section, Article, Heading, TextAccent, Link } from '../../../components';
import { Introduction, Content } from '../Work.style';
import { Logo } from './Kiron.style';
import KironFeatures from './KironFeatures';
import logo from '../../../images/logo-kiron.svg';

/**
 * Work: Kiron composition
 */
const Kiron = () => (
  <StorytellingSection offsetKind="container" whiteSpaceSize={1}>
    <Introduction id="section:kiron">
      <Content>
        <Section>
          <Article>
            <Heading element="h2" weight={500} colorKind="neutral">
              <Logo src={logo} alt="Kiron logo" />
              <Link to="https://kiron.ngo">Kiron</Link>, moving to Germany and helping to build a social learning
              platform for underserved communities.
            </Heading>
            <TextAccent withSpacing>
              I moved from Novi Sad to Berlin to join the product team at Kiron. As a Software Developer, my focus was
              mainly on product foundations and API development. To help shape the product accordingly, I have
              participated in conducting experiments with online campaigns and user testings. In addition to
              product-focused goals, I helped expanding our development team by interviewing and onboarding new team
              members. My latest efforts involved building a white-label platform for our corporate clients and backend
              migration from Laravel to NodeJS.
            </TextAccent>
            <TextAccent>
              <strong>Used technologies:</strong> Docker, Node, Laravel, PosgtreSQL, GraphQL, React, Flow, Emotion,
              Storybook, Jest, Cypress.
            </TextAccent>
          </Article>
        </Section>
      </Content>
    </Introduction>
    <KironFeatures />
  </StorytellingSection>
);

export default Kiron;
