import { css } from '@emotion/core';
import styled from '@emotion/styled';
import scrumSpace from '../../../images/scrum-space-02.jpg';

const wrapperStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  background-color: ${color.white};
  padding: 0 0 70px;
  margin: 0 -15px;
  ${breakpoint.fromPhoneMedium} {
    background-image: url(${scrumSpace});
    background-position: 130px bottom;
    background-repeat: no-repeat;
    background-size: 130%;
    padding-bottom: 150px;
  }
  ${breakpoint.min730} {
    margin: auto;
    display: grid;
    min-height: 100vh;
    grid-template-columns: 100vw 2000px;
    grid-template-rows: auto;
    padding: 0;
    background-position: bottom right;
    background-size: contain;
  }
  ${'' /* > 2000px */}
  @media screen and (min-width: 125em) {
    background-position: 140% bottom;
  }
`;

const imageWrapperStyles = css`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  ${'' /* > 1900px */}
  @media screen and (min-width: 118.75em) {
    max-width: 1400px;
  }
`;

const sectionOneStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  ${breakpoint.min730} {
    padding: 0;
  }
`;

const sectionTwoStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.min730} {
    display: grid;
    align-items: center;
  }

  main {
    padding: 0 15px;
    ${breakpoint.fromPhoneMedium} {
      padding-right: 100px;
    }
    ${breakpoint.min730} {
      max-width: 500px;
      margin-left: 200px;
      padding: 0;
    }
    ${'' /* > 2000px */}
    @media screen and (min-width: 125em) {
      margin-left: 0;
    }
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const SectionOne = styled('div')(sectionOneStyles);
export const SectionTwo = styled('div')(sectionTwoStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
