import { css } from '@emotion/core';
import styled from '@emotion/styled';

const wrapperStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  border-bottom: 2px solid ${color.grey900Transparent15};
  padding: 70px 0;
  ${breakpoint.min730} {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 0;
  }
`;

const logoStyles = css`
  margin-right: 8px;
  position: relative;
  top: 8px;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const Logo = styled('img')(logoStyles);
