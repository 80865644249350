import React from 'react';
import { Section, Article, Heading, TextAccent, Link } from '../../../components';
import { Wrapper, Logo } from './Sesame.style';
import logo from '../../../images/logo-sesame.svg';

/**
 * Work: Sesame composition.
 */
const Sesame = () => (
  <Wrapper id="section:sesame">
    <Section>
      <Article>
        <Heading element="h2" weight={500} colorKind="neutral">
          <Logo src={logo} alt="Sesame logo" />
          <Link to="http://sesamecare.com/">Sesame</Link>, building America’s superstore for great doctors and
          specialists at an affordable price.
        </Heading>
        {/* TODO: Link GitHub repositories to testing strategies and performance/a11y reports */}
        <TextAccent withSpacing>
          I joined the product team at Sesame as a Software Engineer to help build an impactful and unique marketplace
          that provides easy and affordable access to high-quality health care. I currently help improve the membership
          experience and make sure our platform stays reliable by working collaboratively with peers from our governance
          guild. Some of my latest efforts involve improving our analytic infrastructure, exploring testing strategies,
          and ways to generate performance/a11y reports.
        </TextAccent>
        <TextAccent>
          <strong>Technologies we use:</strong> NextJS, Microservices, GCP, GraphQL, TypeScript, Styled Components,
          Storybook, Jest, Cypress.
        </TextAccent>
      </Article>
    </Section>
  </Wrapper>
);

export default Sesame;
