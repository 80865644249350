import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { TextAccent } from '../../../components';
import { rem } from '../../../utils/utils';
import skGaming from '../../../images/sk-gaming.jpg';

const wrapperStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  padding: 70px 15px 35px;
  margin: 0 -15px;
  background: ${work.skGaming};
  background-image: url(${skGaming});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 380px;
  ${breakpoint.fromPhoneSmall} {
    background-position: center 280px;
    background-size: 400px;
  }
  ${breakpoint.fromPhoneMedium} {
    background-position: center 200px;
  }
  ${breakpoint.tablets} and (orientation: landscape) {
    background-size: 600px;
    background-position: 100px bottom;
  }
  ${breakpoint.tablets} and (orientation: portrait) {
    background-size: 1000px;
  }
  ${breakpoint.tablets} and (max-height: 26.25em) and (orientation: landscape) {
    background-position: center 50px;
  }
  ${breakpoint.min730} {
    display: grid;
    grid-template-columns: 100vw 100vw;
    margin: auto;
    min-height: 100vh;
    padding: 0;
    background-position: left bottom;
  }
  ${breakpoint.fromDesktop} {
    grid-template-columns: 650px 100vw;
    background-size: 1000px;
    background-position: 150px bottom;
  }
  ${'' /* > 1200px */}
  @media screen and (min-width: 75em) {
    background-position: center;
  }
`;

const sectionOneStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  padding-bottom: 300px;
  ${breakpoint.min730} {
    margin-top: 150px;
    margin-left: 150px;
    width: 500px;
    padding-bottom: 0;
  }

  a {
    color: ${color.white};
  }
`;

const sectionTwoStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  justify-self: flex-end;
  align-self: flex-end;
  ${breakpoint.min730} {
    width: 600px;
    margin-bottom: 150px;
    margin-right: 150px;
  }
  ${breakpoint.fromDesktop} {
    align-self: center;
    margin-bottom: 0;
  }
  ${'' /* <990px, 610px max-height - small tablets, landscape mode*/}
  @media screen and (max-width: 61.875em) and (max-height: 38.125em) and (orientation: landscape) {
    margin-bottom: 0;
  }

  > article,
  .articleColumn {
    border-top-color: ${color.whiteTransparent15} !important;
  }
`;

const statStyles = css`
  font-size: ${rem(72)};
  line-height: 1;
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const SectionOne = styled('div')(sectionOneStyles);
export const SectionTwo = styled('div')(sectionTwoStyles);
export const Stat = styled(TextAccent)(statStyles);
