import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TextAccent } from '../../../components';
import { Wrapper, SectionOne, SectionTwo, ImageWrapper } from './HLTCorp.style';

/**
 * Work/Superawesome: HLTCorp composition
 */
const HLTCorp = () => {
  const data = useStaticQuery(graphql`
    query {
      hltCorp: file(relativePath: { eq: "hlt-corp-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <SectionOne>
        <ImageWrapper>
          <Img
            fluid={data.hltCorp.childImageSharp.fluid}
            loading="eager"
            imgStyle={{ objectFit: 'contain' }}
            alt="Image of HLT Corp project presentation"
          />
        </ImageWrapper>
      </SectionOne>
      <SectionTwo>
        <main>
          <TextAccent withSpacing>
            <strong>HLT Corp</strong> — Design and development for corporate website and more than 30 landing pages for
            the makers of the top-rated study applications.
          </TextAccent>
          <TextAccent>
            We’ve collaborated with their in-house team who helped us carry through ideations to final results.
          </TextAccent>
        </main>
      </SectionTwo>
    </Wrapper>
  );
};

export default HLTCorp;
