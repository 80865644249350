import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TextAccent } from '../../../components';
import { Wrapper, Section, ImageWrapper } from './Panoram.style';

/**
 * Work/Blueberry: Panoram composition
 */
const Panoram = () => {
  const data = useStaticQuery(graphql`
    query {
      panoram: file(relativePath: { eq: "panoram.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Section>
        <TextAccent colorKind="light">
          <strong>Panoram</strong> — React Native application which provides users to divide their panoramic picture
          into 2, 3, 4 or 5 parts into three easy steps. Created images are compatible with most social app stories,
          like Instagram, Snapchat, or Facebook.
        </TextAccent>
      </Section>
      <ImageWrapper>
        <Img
          fluid={data.panoram.childImageSharp.fluid}
          loading="eager"
          imgStyle={{ objectFit: 'contain' }}
          alt="Image of Panoram project presentation"
        />
      </ImageWrapper>
    </Wrapper>
  );
};

export default Panoram;
