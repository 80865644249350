import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { TextAccent } from '../../../components';
import { Wrapper, Section, ImageWrapper } from './RivieraTour.style';

/**
 * Work/Blueberry: RivieraTour composition
 */
const RivieraTour = () => {
  const data = useStaticQuery(graphql`
    query {
      riviera: file(relativePath: { eq: "riviera-tour.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Section>
        <TextAccent colorKind="light" withSpacing>
          <strong>Travel Companion</strong> — Upcoming travel itinerary at your fingertips built for Riviera Tour.
          Inform about sightseeings and upcoming activities with the app.
        </TextAccent>
        <TextAccent colorKind="light">
          Used technologies: React Native, Redux, Redux-Saga, Mapbox and Styled Components.
        </TextAccent>
      </Section>
      <ImageWrapper>
        <Img
          fluid={data.riviera.childImageSharp.fluid}
          loading="eager"
          imgStyle={{ objectFit: 'contain' }}
          alt="Image of Riviera Tour project presentation"
        />
      </ImageWrapper>
    </Wrapper>
  );
};

export default RivieraTour;
