import React from 'react';
import { StorytellingSection, Section, Article, Heading, Link, TextAccent } from '../../../components';
import { Introduction, Content, Logo } from '../Work.style';
import HLTCorp from './HLTCorp';
import SKGaming from './SKGaming';
import logo from '../../../images/logo-superawesome.svg';

/**
 * Work: Superawesome composition
 */
const Superawesome = () => (
  <StorytellingSection offsetKind="container" whiteSpaceSize={1}>
    <Introduction id="section:superawesome">
      <Content>
        <Section>
          <Article>
            <Heading element="h2" weight={500} colorKind="neutral">
              <Logo src={logo} alt="Superawesome logo" />
              <Link to="https://sprawsm.com">Superawesome</Link>, first job, first hands-on experience with
              international clients and pushing personal boundaries.
            </Heading>
            <TextAccent withSpacing>
              When I joined Superawesome, I was &frac14; of the company, which for a design agency means, a plethora of
              responsibilities. I find myself lucky enough to have been exposed to so many different challenges from the
              early beginning and for being trusted on delivering my best work at that time. This helped me grow beyond
              my imagination and it helped me mold further steps in the development of my career. Being one of the best
              agencies in Serbia, I had an opportunity to work on a wide variety of interesting projects from respected
              brands across the world.
            </TextAccent>
            <TextAccent>
              I served as a Product Designer and Front-end Developer, and I lead direct communication with the
              stakeholders of various size making sure projects evolve in the right direction.
            </TextAccent>
          </Article>
        </Section>
      </Content>
    </Introduction>
    <HLTCorp />
    <SKGaming />
  </StorytellingSection>
);

export default Superawesome;
