import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { linkStyles } from '../../components/Link/Link.style';
import chevronDown from '../../images/icon-chevron-down.svg';

export const introStylesOverride = css`
  min-height: calc(100vh);
`;

const headerStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  max-width: 480px;
  text-align: center;
  margin: 70px auto;
  ${breakpoint.fromPhoneMedium} {
    margin: 140px auto;
  }
`;

const navListStyles = ({
  theme: {
    grid: { breakpoint },
    color,
  },
}) => css`
  list-style-type: none;
  margin: 0 auto;
  padding: 0 0 0 6px;
  max-width: 160px;
  position: relative;
  min-height: 200px;
  overflow: hidden;
  ${breakpoint.fromPhoneSmall} {
    min-height: 300px;
  }
  ${breakpoint.fromPhoneMedium} {
    min-height: calc(100vh - 510px);
  }
  &::before {
    content: '';
    width: 1px;
    top: 14px;
    left: 5px;
    min-height: 200px;
    background-color: ${color.blueGrey100};
    position: absolute;
    ${breakpoint.fromPhoneSmall} {
      min-height: 300px;
    }
    ${breakpoint.fromPhoneMedium} {
      height: calc(100vh - 510px);
    }
  }
`;

const navItemStyles = css`
  margin-bottom: 5px;
  &::after {
    content: '';
    width: 12px;
    height: 12px;
    display: inline-block;
    transition: 0.1s all ease-in-out;
    background-image: url(${chevronDown});
    background-size: cover;
    position: absolute;
    bottom: -1px;
    left: -1px;
    opacity: 0.08;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;

const navLinkStyles = ({
  theme: {
    grid: { breakpoint },
    color,
  },
}) => css`
  position: relative;
  display: block;
  &:before {
    content: '— ';
    color: ${color.blueGrey100};
    transition: 0.1s all ease-in-out;
  }
  &:after {
    content: '';
    width: 1px;
    top: 13px;
    height: calc(100vh - 350px);
    background-color: ${color.grey900};
    position: absolute;
    left: -1px;
    opacity: 0;
    z-index: 2;
    transition: 0.1s all ease-in-out;
    ${breakpoint.fromPhoneMedium} {
      height: calc(100vh - 510px);
    }
  }
  &:hover {
    &::before {
      color: ${color.grey900};
    }
    &::after {
      opacity: 1;
    }
  }
`;

/**
 * Component style declarations
 */
export const Header = styled('header')(headerStyles);
export const NavList = styled('ul')(navListStyles);
export const NavItem = styled('li')(navItemStyles);
export const NavLink = styled('a')(linkStyles, navLinkStyles);
