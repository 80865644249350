import React from 'react';
import PropTypes from 'prop-types';
import Page from '../templates/Page';
import { SEO } from '../components';
import { Intro, Superawesome, Sesame, Kiron, Blueberry, Personal, StickyNavigation } from '../compositions/Work';

/**
 * Work page
 */
const Work = ({ path }) => (
  <>
    <Page preContent={<Intro />}>
      <StickyNavigation />
      <SEO title="Previous experience | Danijel Grabež" pagePath={path} />
      <Sesame />
      <Kiron />
      <Blueberry />
      <Superawesome />
      <Personal />
    </Page>
  </>
);

Work.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Work;
