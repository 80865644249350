/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Heading, TextAccent, Intro } from '../../components';
import { Header, introStylesOverride, NavList, NavItem, NavLink } from './Intro.style';
import { ml } from '../../utils/classes';

/**
 * Work: WorkIntro composition
 */
const WorkIntro = () => (
  <Intro pageTitle="Work experience" css={introStylesOverride}>
    <Header>
      <Heading element="h1">“Only work and play”</Heading>
      <TextAccent colorKind="darkTransparent">
        Across all stages of product development, simplifying digital experiences for clients and customers.
      </TextAccent>
    </Header>
    <NavList>
      <NavItem>
        <NavLink href="#section:sesame" linkKind="no-underline">
          Sesame
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:kiron" linkKind="no-underline">
          Kiron
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:blueberry" linkKind="no-underline">
          Blueberry
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:superawesome" linkKind="no-underline">
          Superawesome
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href="#section:personal" linkKind="no-underline">
          Freelance /<div css={ml(23)}>personal work</div>
        </NavLink>
      </NavItem>
    </NavList>
  </Intro>
);

export default WorkIntro;
