import React from 'react';
import { StorytellingSection, Section, Article, Heading, TextAccent } from '../../../components';
import { Introduction, Content } from '../Work.style';
import PersonalWork from './PersonalWork';
import ItenEngineering from './ItenEngineering';

/**
 * Work/Personal composition
 */
const Personal = () => (
  <StorytellingSection offsetKind="container" whiteSpaceSize={1}>
    <Introduction id="section:personal">
      <Content>
        <Section>
          <Article>
            <Heading element="h2" weight={500} colorKind="neutral">
              Personal work, open-source projects, and long-term collaboration
            </Heading>
            <TextAccent withSpacing>
              I enjoy exploring different technologies by creating internal solutions for the projects I work on. I
              prefer honing long-term relationships on projects for any area of digital business.
            </TextAccent>
            <TextAccent>When possible, I am contributing to open source community.</TextAccent>
          </Article>
        </Section>
      </Content>
    </Introduction>
    <PersonalWork />
    <ItenEngineering />
  </StorytellingSection>
);

export default Personal;
