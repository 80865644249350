import React from 'react';
import { Article, ArticleColumn, Heading, TextAccent, ArrowExternal } from '../../../components';
import { Wrapper, SectionOne, SectionTwo, Stat } from './SKGaming.style.js';

/**
 * Work/Superawesome: SKgaming composition
 */
const SKGaming = () => {
  return (
    <Wrapper>
      <SectionOne>
        <TextAccent colorKind="light" withSpacing>
          <strong>SK Gaming</strong> — Reorganizing, rebranding and developing a website for a leading eSports
          organization. More than 20 years of diverse content was reimagined — news, videos, matches, rankings, team and
          user profiles and social aspects.
        </TextAccent>
      </SectionOne>
      <SectionTwo>
        <Article variation="bt">
          <Heading colorKind="light" element="h3" size="h2" weight={500}>
            Fun fact
          </Heading>
          <TextAccent colorKind="light">
            Back in 2015, this was the most challenging project I’ve worked on, since I was responsible for most of the
            project pipeline. At that time, to automatise and simplify the development workflow, I was leveraging the
            power of the Pug templating language and Grunt task runner.
          </TextAccent>
        </Article>
        <Article variation="1/3">
          <ArticleColumn>
            <Heading colorKind="light" element="h3" size="h2" weight={500}>
              Years of content
            </Heading>
            <Stat colorKind="light">
              <ArrowExternal size={64} />
              <br />
              20
            </Stat>
          </ArticleColumn>
          <ArticleColumn>
            <Heading colorKind="light" element="h3" size="h2" weight={500}>
              Consistency and attention to details
            </Heading>
            <TextAccent colorKind="light">
              As one of the most respected eSport clubs in the world, with several million daily impressions, a
              particular effort was involved in providing consistency and forming the proper brand presence on the web.
            </TextAccent>
          </ArticleColumn>
        </Article>
      </SectionTwo>
    </Wrapper>
  );
};

export default SKGaming;
