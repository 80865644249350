import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from '../../../utils/utils';
import img from '../../../images/tile-hey-polls.png';
import emoji1 from '../../../images/emoji-01.svg';
import emoji2 from '../../../images/emoji-02.svg';

const wrapperStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  background: ${color.white};
  display: flex;
  flex-direction: column;
  padding: 0;
  ${breakpoint.fromPhoneMedium} {
    padding: 120px 0 0;
  }
  ${breakpoint.min730} {
    height: 100vh;
    align-items: center;
    flex-direction: row;
    padding: 0;
  }
`;

const columnStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-bottom: 70px;
  ${breakpoint.min730} {
    width: 500px;
    margin-left: 150px;
    margin-right: 150px;
    margin-bottom: 0;
    &:last-of-type {
      margin-left: 300px;
    }
  }
`;

const labelStyles = ({ theme: { color } }) => css`
  display: inline-block;
  color: ${color.amber900};
  text-transform: uppercase;
  font-size: ${rem(12)};
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  line-height: 1;
`;

const messageBubbleStyles = ({
  theme: {
    color,
    grid: { breakpoint },
  },
}) => css`
  display: flex;
  align-items: flex-end;
  border-radius: 40px 40px 5px 5px;
  position: relative;
  background: ${color.grey50};
  margin-bottom: 30px;
  ${breakpoint.fromPhoneMedium} {
    height: 180px;
    border-radius: 75px 75px 75px 10px;
  }
  ${breakpoint.min730} {
    max-width: 600px;
    margin-left: -50px;
    margin-right: -50px;
  }
`;

const heyPollsImageStyles = css`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;

const emojiOneStyles = css`
  width: 64px;
  position: absolute;
  top: 30px;
  left: -15px;
  transform: rotate(-15deg);
`;

const emojiTwoStyles = css`
  width: 48px;
  position: absolute;
  right: 30px;
  bottom: -15px;
  transform: rotate(15deg);
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const Column = styled('div')(columnStyles);
export const Label = styled('strong')(labelStyles);
const MessageBubble = styled('div')(messageBubbleStyles);
const HeyPollsImage = styled('img')(heyPollsImageStyles);
const EmojiOne = styled('img')(emojiOneStyles);
const EmojiTwo = styled('img')(emojiTwoStyles);

export const HeyPolls = () => (
  <MessageBubble>
    <EmojiOne src={emoji1} alt="Emoji" />
    <EmojiTwo src={emoji2} alt="Emoji" />
    <HeyPollsImage src={img} alt="Image of phone with Hey,Polls! logo" />
  </MessageBubble>
);
