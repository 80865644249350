import { css } from '@emotion/core';
import styled from '@emotion/styled';
import hltCorp from '../../../images/hlt-corp-02.jpg';

const wrapperStyles = ({
  theme: {
    work,
    grid: { breakpoint },
  },
}) => css`
  background-color: ${work.hltCorp};
  padding: 70px 0;
  margin: 0 -15px;
  ${breakpoint.fromPhoneMedium} {
    padding: 70px 0 0;
  }
  ${breakpoint.min730} {
    margin: auto;
    display: grid;
    min-height: 100vh;
    grid-template-columns: 100vw 1400px;
    grid-template-rows: auto;
    padding: 0;
  }
`;

const imageWrapperStyles = css`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  ${'' /* > 1900px */}
  @media screen and (min-width: 118.75em) {
    max-width: 1400px;
  }
`;

const sectionOneStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  ${breakpoint.min730} {
    padding: 0;
  }
`;

const sectionTwoStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  ${breakpoint.fromPhoneMedium} {
    padding-bottom: 350px;
    background-image: url(${hltCorp});
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
  }
  ${breakpoint.min730} {
    display: grid;
    width: 100%;
    max-width: 1000px;
    grid-template-columns: 500px 1fr;
    align-items: center;
    padding-bottom: 0;
    justify-self: flex-end;
  }
  ${breakpoint.fromDesktop} {
    max-width: 1200px;
  }

  main {
    padding: 0 15px;
    ${breakpoint.fromTablet} {
      padding: 0;
    }
  }
`;

/**
 * Component style declarations
 */
export const Wrapper = styled('div')(wrapperStyles);
export const SectionOne = styled('div')(sectionOneStyles);
export const SectionTwo = styled('div')(sectionTwoStyles);
export const ImageWrapper = styled('div')(imageWrapperStyles);
